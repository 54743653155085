const COMMON = {
    ArrowRight: require("../../../assets/images/arrow-right.png"),
    ArrowRightDouble: require("../../../assets/images/arrow-right-double.png"),
    ArrowLeftBlack: require("../../../assets/images/arrow-left-black.png"),
    ArrowRightBlack: require("../../../assets/images/arrow-right-black.png"),
    ArrowUpWhite: require("../../../assets/images/arrow-up-white.png"),
    Headphones: require("../../../assets/images/headphones.png"),
    Map: require("../../../assets/images/location.png"),
    Envelope: require("../../../assets/images/envelope.png"),
    FacebookWhite: require("../../../assets/images/facebook-white.png"),
    TwitterWhite: require("../../../assets/images/twitter-white.png"),
    InstagramWhite: require("../../../assets/images/instagram-white.png"),
}
const HOME = {
    Logo: require("../../../assets/images/logo.png"),
    Bar: require("../../../assets/images/bar.png"),
    LogoWhite: require("../../../assets/images/logo-white.png"),
    CommonHeaderShape2: require("../../../assets/images/header-common-shape-2.png"),
    CommonHeaderShape4: require("../../../assets/images/header-common-shape-4.png"),
    CommonHeaderShape5: require("../../../assets/images/testimonial-shape.png"),
    CommonHeaderShape6: require("../../../assets/images/dots-box.png"),
    CommonHeaderShape12: require("../../../assets/images/temp-shape.png"),
    HomeBannerIcon: require("../../../assets/images/home-banner-icon.png"),
    OurExpert: require("../../../assets/images/our-expert.png"),
    DotsBox: require("../../../assets/images/dots-box.png"),
    DotsBox1: require("../../../assets/images/dots-box-1.png"),
    ServiceIcon1: require("../../../assets/images/service-icon1.png"),
    ServiceIcon2: require("../../../assets/images/service-icon2.png"),
    ServiceIcon3: require("../../../assets/images/service-icon3.png"),
    ServiceIcon4: require("../../../assets/images/service-icon4.png"),
    PlusIconRed: require("../../../assets/images/plus-red-icon.png"),
    CircleTick: require("../../../assets/images/circle-red-tick.png"),
    Portfolio1: require("../../../assets/images/portfolio-1.png"),
    Portfolio2: require("../../../assets/images/portfolio-2.png"),
    Portfolio3: require("../../../assets/images/portfolio-3.png"),
    Portfolio4: require("../../../assets/images/portfolio-4.png"),
    Portfolio5: require("../../../assets/images/portfolio-5.png"),
    Portfolio6: require("../../../assets/images/portfolio-6.png"),
    Portfolio7: require("../../../assets/images/portfolio-7.png"),
    Portfolio8: require("../../../assets/images/portfolio-8.png"),
    Testimonial1: require("../../../assets/images/testimonial-1.jpg"),
    Testimonial2: require("../../../assets/images/testimonial-2.jpg"),
    QuoteLeft: require("../../../assets/images/quote-left-black.png"),
    QuoteRight: require("../../../assets/images/quote-right-black.png"),
}
const ABOUT = {
    AboutBanner: require("../../../assets/images/about-banner.png"),
    HowADayStart: require("../../../assets/images/how-day-start.png"),
    QuickResponse: require("../../../assets/images/quick-response.png"),
    GreatCommunication: require("../../../assets/images/great-communication.png"),
    CustomerSatisfaction: require("../../../assets/images/customer-satisfaction.png"),
    Expert1: require("../../../assets/images/expert-1.png"),
    Expert2: require("../../../assets/images/expert-2.png"),
    Expert3: require("../../../assets/images/expert-3.png"),
    Expert4: require("../../../assets/images/expert-4.png"),
    Expert5: require("../../../assets/images/expert-5.png"),
    Expert6: require("../../../assets/images/expert-6.png"),
    Expert7: require("../../../assets/images/expert-7.png"),
    Expert8: require("../../../assets/images/expert-8.png"),
    Expert9: require("../../../assets/images/expert-9.png"),
    Expert10: require("../../../assets/images/expert-10.png"),
    Expert11: require("../../../assets/images/expert-11.png"),
    Expert12: require("../../../assets/images/expert-12.png"),
    Expert13: require("../../../assets/images/expert-13.png"),
    Expert14: require("../../../assets/images/expert-14.png"),
    Expert15: require("../../../assets/images/expert-15.png"),
}
const SERVICES = {
    Java: require("../../../assets/images/java.png"),
    Swift: require("../../../assets/images/swift.png"),
    Flutter: require("../../../assets/images/flutter.png"),
    ReactNative: require("../../../assets/images/react-native.png"),
    PhoneGap: require("../../../assets/images/phone-gap.png"),
    Node: require("../../../assets/images/node.png"),
    Symfony: require("../../../assets/images/node.png"),
    Laravel: require("../../../assets/images/laravel.png"),
    WordPress: require("../../../assets/images/wordpress.png"),
    React: require("../../../assets/images/react-native.png"),
    MobileServices: require("../../../assets/images/mobile-services.png"),
    DesktopServices: require("../../../assets/images/desktop-services.png"),
    HybridMobileApp: require("../../../assets/images/hybrid-app.png"),
    IosApp: require("../../../assets/images/ios-app.png"),
    AndroidApp: require("../../../assets/images/android-app.png"),
    WhatWeIcon1: require("../../../assets/images/what-we-do-icon-1.png"),
    WhatWeIcon2: require("../../../assets/images/what-we-do-icon-2.png"),
    WhatWeIcon3: require("../../../assets/images/what-we-do-icon-3.png"),
    WhatWeIcon4: require("../../../assets/images/what-we-do-icon-4.png"),
}
const PORTFOLIO = {
    Project1: require("../../../assets/images/project-1.png"),
    Project2: require("../../../assets/images/project-2.png"),
    Project3: require("../../../assets/images/project-3.png"),
}
const CONTACTUS = {
    Map: require("../../../assets/images/map.png"),
    Email: require("../../../assets/images/email.png"),
    Phone: require("../../../assets/images/phone.png"),
    Skype: require("../../../assets/images/skype.png"),
}
export const ASSETS = {
    COMMON,
    HOME,
    ABOUT,
    SERVICES,
    PORTFOLIO,
    CONTACTUS
};
