import * as React from "react";
import {ASSETS} from "../images";

const Footer = () => {
    return (
        <div>
            <section className="footer-sec-wrapper">
                <a href="#" className="gb-arrow-up"><img src={ASSETS.COMMON.ArrowUpWhite} alt="" /></a>
                <div className="container gb-ft-main">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="common-footer-col footer-col-1">
                                <a href="/"><img src={ASSETS.HOME.LogoWhite} alt="" className="footer-logo" /></a>
                                <p className="gb-ft-about-desc">We are a team of highly skilled professionals who take care of your business in every aspect. We are very passionate about the technology and we have outsourcing clients from all over the world.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="common-footer-col footer-col-2">
                                <h3 className="title">Company</h3>
                                <ul className="footer-menu-list-wrapper">
                                    <li><img src={ASSETS.COMMON.ArrowRightDouble} alt="" /> <a href="/">Home</a></li>
                                    <li><img src={ASSETS.COMMON.ArrowRightDouble} alt="" /> <a href="/about">About us</a></li>
                                    <li><img src={ASSETS.COMMON.ArrowRightDouble} alt="" /> <a href="services">Services</a></li>
                                    <li><img src={ASSETS.COMMON.ArrowRightDouble} alt="" /> <a href="/portfolio">Portfolio</a></li>
                                    <li><img src={ASSETS.COMMON.ArrowRightDouble} alt="" /> <a href="/contact-us">Contact us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="common-footer-col footer-col-3">
                                <h3 className="title">Get In Touch With Us</h3>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="gb-common-contact-details-ft gb-ft-mobile">
                                            <img src={ASSETS.COMMON.Headphones} alt="" className="gb-common-contact-icon" /> <a href="tel: +91 172 417 2777">+91 172 417 2777</a>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="gb-common-contact-details-ft gb-ft-address">
                                            <img src={ASSETS.COMMON.Map} alt="" className="gb-common-contact-icon" /> <p>Plot No 759, Third Floor, Sector 82, JLPL Industrial Area, Sahibzada Ajit Singh Nagar, Punjab 160055</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="gb-common-contact-details-ft gb-ft-envelope">
                                            <img src={ASSETS.COMMON.Envelope} alt="" className="gb-common-contact-icon" /> <a href="mailto: Contact@geekybnes.com">Contact@geekybnes.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="footer-sec-bottom-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <p className="copyright-text">2021 GeekyBones Lab Private Limited. All Rights Reserved</p>
                        </div>
                        <div className="col-md-4">
                            <ul className="gb-ft-social-icon-list">
                                <li><a href="#"><img src={ASSETS.COMMON.FacebookWhite} className="facebook" alt="facebook icon" /></a></li>
                                <li><a href="#"><img src={ASSETS.COMMON.TwitterWhite} className="twitter" alt="twitter icon" /></a></li>
                                <li><a href="#"><img src={ASSETS.COMMON.InstagramWhite} className="instagram" alt="instagram icon" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Footer;
