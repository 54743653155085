import * as React from "react";
import {ASSETS} from "../images";
import {Link} from "gatsby";

const Header = () => {
    return (
        <section className="gb-header-wrapper">
            <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="/"><img src={ASSETS.HOME.Logo} className="logo" alt="logo" /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <img src={ASSETS.HOME.Bar} className="bar-icon" alt="" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <button className="cancel-btn-wrapper" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <img src={ASSETS.HOME.PlusIconRed} className="cancel-icon" alt="" />
                    </button>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to={'/'} className="nav-link" activeClassName="active" aria-current="page">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/about'} className="nav-link" activeClassName="active" aria-current="page">About us</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/services'} className="nav-link" activeClassName="active" aria-current="page">Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/portfolio'} className="nav-link" activeClassName="active" aria-current="page">Portfolio</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={'/contact-us'} activeClassName="active" aria-current="page">Contact us</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        </section>
    )
}
export default Header;
